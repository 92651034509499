import React from 'react';

export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div id="gallery" className="gallery">
                <div className="container">
                    <h3 className="tittle">Gallery</h3>
                    <div className="gallery-grids">
                        <div className="baner-row">
                            <div className="baner-top">
                                <figure className="effect-bubba">
                                    <a href={window.location.origin + '/gallery/721164140.jpg'} rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={window.location.origin + '/gallery/721164140.jpg'} alt="" className="img-responsive" />
                                        <figcaption>
                                        <h4>2020 融侨金融网球慈善邀请赛</h4>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="clearfix"> </div>
                        </div>
                        <div className="baner-row">
                            <div className="col-md-4 baner-bottom">
                                <figure className="effect-bubba">
                                    <a href={window.location.origin + '/gallery/8278.jpg'} rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={window.location.origin + '/gallery/8278.jpg'} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">2020 融侨金融网球慈善邀请赛</h4>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="col-md-4 baner-bottom">
                                <figure className="effect-bubba">
                                    <a href={window.location.origin + '/gallery/8247.jpg'} rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={window.location.origin + '/gallery/8247.jpg'} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">2020 融侨金融网球慈善邀请赛</h4>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="col-md-4 baner-bottom">
                                <figure className="effect-bubba">
                                    <a href={window.location.origin + '/gallery/7270.jpg'} rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={window.location.origin + '/gallery/7270.jpg'} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">2020 融侨金融网球慈善邀请赛</h4>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="clearfix"> </div>
                        </div>

                        <div className="baner-row">
                            <div className="col-md-6 baner-top">
                                <figure className="effect-bubba">
                                    <a href={window.location.origin + '/gallery/1449373302.jpg'} rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={window.location.origin + '/gallery/1449373302.jpg'} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4>2020 融侨金融网球慈善邀请赛</h4>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="col-md-6 baner-top">
                                <figure className="effect-bubba">
                                    <a href="images/g2.jpg" rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={require("../assets/images/g12.jpg")} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">Social</h4>
                                            <p>Friend...ship</p>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="clearfix"> </div>
                        </div>
                        {/* <div className="baner-row">
                            <div className="col-md-4 baner-bottom">
                                <figure className="effect-bubba">
                                    <a href="images/g3.jpg" rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={require("../assets/images/g13.jpg")} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">Develop</h4>
                                            <p>Developing skills</p>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="col-md-4 baner-bottom">
                                <figure className="effect-bubba">
                                    <div rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={require("../assets/images/g4.jpg")} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">Social</h4>
                                            <p>Friend...ship</p>
                                        </figcaption>
                                    </div>
                                </figure>
                            </div>
                            <div className="col-md-4 baner-bottom">
                                <figure className="effect-bubba">
                                    <a href="images/g5.jpg" rel="title" className="b-link-stripe b-animate-go  thickbox">
                                        <img src={require("../assets/images/g5.jpg")} alt="" className="img-responsive" />
                                        <figcaption>
                                            <h4 className="sec">Develop</h4>
                                            <p>Developing skills</p>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                            <div className="clearfix"> </div>
                        </div> */}
                        <div className="clearfix"> </div>
                    </div>
                </div>
            </div>
        );
    }
}