//member form
import React from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

const initialValues = {
  name: '',
  email: '',
  mobile: '',
  nominee: '',
  idnumber: '',
  form: 'member application'
};

const validations = {
  name: Yup.string().min(2, "* minimum 2 character").required("* Required"),
  nominee: Yup.string().min(2, "* minimum 2 character").required("* Required"),
  idnumber: Yup.string().min(2, "* minimum 4 character").required("* Required"),
  mobile: Yup.string().trim().matches(/^(?:\+?61|0)4 ?(?:(?:[01] ?[0-9]|2 ?[0-57-9]|3 ?[1-9]|4 ?[7-9]|5 ?[018]) ?[0-9]|3 ?0 ?[0-5])(?: ?[0-9]){5}$/, '* Invalid Australian Mobile Number' ).required("* Required"),
  email: Yup.string()
    .email()
    .required("* Required")
}

export default class MemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
  }

  

  render() {
    return (
      <div class="contact" id="memberform">
        <div class="container">
          <h3 class="tittle">Membership Application</h3>
          <div class="contact-form">
            <div class="col-md-6 contact-in">
              <h5>Apply for membership</h5>
              <p class="para1"><i class="glyphicon glyphicon-check"></i> Submit membership application to Sino Tennis Club. </p>
              <p class="para1"><i class="glyphicon glyphicon-check"></i> Join "know the club" tennis section with nomination club member</p>
              <p class="para1"><i class="glyphicon glyphicon-check"></i> ID verification during "know the club"</p>
              <p class="para1"><i class="glyphicon glyphicon-check"></i> Membership payment</p>
              <p class="para1"><i class="glyphicon glyphicon-check"></i> Membership starts once payment is received.</p>
              <div style={{ marginTop: '0.9em' }}>
                <p class="para1">Note:</p><p class="location"><i class="glyphicon glyphicon-exclamation-sign"></i>Applicant must be nominated by one of our existed member.</p>
                <p class="location"><i class="glyphicon glyphicon-exclamation-sign"></i>Applicant information need to be correct and matched with photo ID.</p>
              </div>
            </div>
            <div class="col-md-6 contact-grid">
              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                  const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    redirect: 'follow',
                    body: JSON.stringify(values)
                  };
                  
                  try {
                    const fetchResponse = await fetch(`https://app.99inbound.com/api/e/KGtClQfs`, settings);
                    const data = await fetchResponse.json();
                    // console.log(data);
                    resetForm();
                    alert("We have received your membership application, please contact your nominee to organize your first tennis sssion with us. Thanks and welcome!");
                  } catch (e) {
                    // console.log(e);
                    alert("Sorry, there's an error while submitting your application, please try again later or contact our support team.");
                  } 
                  // await new Promise(resolve => setTimeout(resolve, 500));
                  // alert(JSON.stringify(values, null, 2));
                }}
                validationSchema={Yup.object().shape(validations)}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} style={{marginTop: '1rem'}}>
                      {/* Name */}
                      <p class="your-para">Name: 
                      {errors.name && touched.name && (
                        <span className="input-feedback">{errors.name}</span>
                      )}</p>
                      <input
                        id="name"
                        placeholder="Enter your name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name && touched.name
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      
                      {/* ID */}
                      <p class="your-para">Photo ID number: 
                      {errors.idnumber && touched.idnumber && (
                        <span className="input-feedback">{errors.idnumber}</span>
                      )}</p>
                      <input
                        id="idnumber"
                        placeholder="Enter your id number"
                        type="text"
                        value={values.idnumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.idnumber && touched.idnumber
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      
                      {/* Mobile */}
                      <p class="your-para">Mobile: 
                      {errors.mobile && touched.mobile && (
                        <span className="input-feedback">{errors.mobile}</span>
                      )}</p>
                      <input
                        id="mobile"
                        placeholder="Enter your mobile"
                        type="text"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.mobile && touched.mobile
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      

                      {/* -- Email -- */}
                      <p class="your-para">Email: 
                      {errors.email && touched.email && (
                        <span className="input-feedback">{errors.email}</span>
                      )}</p>
                      <input
                        id="email"
                        placeholder="Enter your email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      

                      {/* Nominee */}
                      <p class="your-para">Nominee: 
                      {errors.nominee && touched.nominee && (
                        <span className="input-feedback">{errors.nominee}</span>
                      )}</p>
                      <input
                        id="nominee"
                        placeholder="Enter your nominee"
                        type="text"
                        value={values.nominee}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.nominee && touched.nominee
                            ? "text-input error"
                            : "text-input"
                        }
                      />

                      <div class="send">
                        {/* <button
                type="button"
                className="outline"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >Reset</button> */}
                        <button type="submit" disabled={isSubmitting}>Submit</button>
                      </div>
                      {/* <DisplayFormikState {...props} /> */}
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div class="clearfix"> </div>
          </div>
        </div>
      </div>
    );
  }
}