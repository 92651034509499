import React from 'react';

export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    render() {
        return (
            <div className="contact" id="events">
                <div className="container">
                    <h3 className="tittle">Activities and Events</h3>
                    <div className="contact-form">

                        <div className="col-md-6 contact-in">
                            <h5>Sunday Tennis</h5>
                            <p className="para1">Member tennis session every Sunday 3pm - 6pm @ Artarmon Community Tennis Center.  </p>
                            <div className="more-address">
                                <div className="address-more">
                                    <p className="location"><i className="glyphicon glyphicon-map-marker"></i>Artarmon Community Tennis - Hampden Rd, Artarmon NSW 2064</p>
                                    {/* <p className="phone"><i className="glyphicon glyphicon-phone"></i> 655 7758 2068 54892</p> */}
                                    <p className="mail"><i className="glyphicon glyphicon-envelope"></i><a href="mailto:info@sinotennisclub.com">info@sinotennisclub.com</a></p>

                                </div>
                            </div>
                        </div><div className="col-md-6 contact-grid">
                            <div className="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.0262552682107!2d151.18506931586475!3d-33.81163552352687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12aed7fc0e416d%3A0xf036e768ea244fc2!2sArtarmon%20Community%20Tennis!5e0!3m2!1sen!2sau!4v1579320758771!5m2!1sen!2sau" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                </div>
            </div>
        );
    }
}