import React from 'react';
// import './App.css';
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import $ from 'jquery';

import './assets/css/bootstrap.css';
import './assets/css/style.css';

import Header from './components/header';
import Banner from './components/banner';
import ContactUs from './components/contactus';
import Footer from './components/footer';
import Gallery from './components/gallery';
import Events from './components/events';
import MemberForm from './components/memberform';
import Sponsors from './components/sponsors';

class App extends React.Component {

	render() {
		return (
			<div className="App">
				<Header />
				<Banner />
				<Sponsors/>
				<Events />
				<ContactUs/>
				<MemberForm/>
				{/* <Location/> */}
				<Gallery />
				<Footer />
			</div>
		);
	}
}

export default App;
