import React from 'react';

export default class Sponsors extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="sponsors" className="sponsors">
                <div className="container">
                    <h3 className="tittle">Sponsors and Partners</h3>
                    <div className="gallery-grids">
                        <div className="col-md-6 baner-top" onClick={()=> window.open("https://jdadvisory.com.au/", "_blank")}>
                            <figure className="effect-bubba">
                                <div rel="title" className="b-link-stripe b-animate-go  thickbox" style={{ padding: '1px' }}>
                                    <a href="abc.com"><img src={require("../assets/images/jd_logo_200x79.png")} alt="" className="img-responsive" /></a>
                                    <figcaption>
                                        <h4 className="sec">JD Advisory Group</h4>
                                    </figcaption>
                                </div>
                            </figure>
                        </div>
                    </div>
                    <div className="clearfix"> </div>
                </div>
            </div>
        );
    }
}