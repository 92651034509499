import React from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

const initialValues = {
    form: 'contactus',
    name: '',
    email: '',
    message: ''
};

const validations = {
    name: Yup.string().min(2, "* minimum 2 characters").required("* Required"),
    message: Yup.string().min(5, "* minimum 5 characters").max(500, "* maximun 500 characters").required("* Required"),
    email: Yup.string()
        .email()
        .required("* Required")
}

export default class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    render() {
        return (
            <div class="contact" id="contact">
            <div class="container">
              <h3 class="tittle">Contact Us</h3>
              <div class="contact-form">
                <div class="col-md-6 contact-in">
                  <h5>Our contact information</h5>
                  <p class="para1">Please contact us via email or contact us form. </p>
                  <p class="para1">We will get back to you ASAP.</p>
                  <div style={{ marginTop: '0.9em' }}>
                  <p className="mail"><i className="glyphicon glyphicon-envelope"></i><a href="mailto:info@sinotennisclub.com">info@sinotennisclub.com</a></p>
                  </div>
                </div>
                <div class="col-md-6 contact-grid">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, { resetForm }) => {
                      const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        redirect: 'follow',
                        body: JSON.stringify(values)
                      };
                      
                      try {
                        const fetchResponse = await fetch(`https://app.99inbound.com/api/e/KGtClQfs`, settings);
                        const data = await fetchResponse.json();
                        resetForm();
                        alert("Thanks for contact us, we will get back to you ASAP.");
                      } catch (e) {
                        console.log(e);
                        alert("Sorry, there's an error while submitting your application, please try again later or contact our support team.");
                      } 
                      // await new Promise(resolve => setTimeout(resolve, 500));
                      // alert(JSON.stringify(values, null, 2));
                    }}
                    validationSchema={Yup.object().shape(validations)}
                  >
                    {props => {
                      const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset
                      } = props;
                      return (
                        <form onSubmit={handleSubmit} style={{marginTop: '1rem'}}>
                          {/* Name */}
                          <p class="your-para">Your Name: 
                          {errors.name && touched.name && (
                            <span className="input-feedback">{errors.name}</span>
                          )}</p>
                          <input
                            id="name"
                            placeholder="Enter your name"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.name && touched.name
                                ? "text-input error"
                                : "text-input"
                            }
                          />
    
                          {/* -- Email -- */}
                          <p class="your-para">Your email: 
                          {errors.email && touched.email && (
                            <span className="input-feedback">{errors.email}</span>
                          )}</p>
                          <input
                            id="email"
                            placeholder="Enter your email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.email && touched.email
                                ? "text-input error"
                                : "text-input"
                            }
                          />
                          
    
                          {/* Nominee */}
                          <p class="your-para">Your Message: 
                          {errors.message && touched.message && (
                            <span className="input-feedback">{errors.message}</span>
                          )}</p>
                          <textarea
                            id="message"
                            placeholder="Enter your message"
                            type="text"
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.message && touched.message
                                ? "text-input error"
                                : "text-input"
                            }
                          />
    
                          <div class="send">
                            {/* <button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                  >Reset</button> */}
                            <button type="submit" disabled={isSubmitting}>Submit</button>
                          </div>
                          {/* <DisplayFormikState {...props} /> */}
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div class="clearfix"> </div>
              </div>
            </div>
          </div>
            );
    }
}