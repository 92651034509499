import React from 'react';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="copy">
                <p>Copyright &copy; 2020 Sino Tennis Club</p>
            </div>
        );
    }
}