import React from 'react';

export default class Banner extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="banner-section">
                <div className="container">
                    <div className="banner-bottom">
                        <div className="text-bottom">
                            <h4>About Us</h4>
                            <p>Our tennis club is a non-profit organization dedicated to providing a platform for players who love tennis in Sydney. Our objective is to provide members and for members guests a social and sporting club, also to assist in the promotion and propagation of tennis in the Chinese community in Sydney but not limited to.</p>
                            <p>To achieve these objectives, our club has adopted the following strategies: <br />* Provision of social tennis activities and events.<br/>* Organise and promote competitive tennis amongst members.</p>

                        </div>
                        <div className="clearfix"></div>
                    </div>                    
                </div>

            </div>);
    }
}