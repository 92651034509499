import React from 'react';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
    }


    _toggle = (e) => {
        const $topmenu = document.getElementById('top-menu')
        if ($topmenu.style.display === "none" || !$topmenu.style.display) {
            $topmenu .style.display = "block";
          } else {
            $topmenu .style.display = "none";
          }
    }

    _scrollTo = (e) => {
        // e.preventDefault();
        this._toggle();
        let offset = 100;
        try {
          const elementName = e.currentTarget.getAttribute('href');
          console.log("_smoothScroll:", elementName);
          const $anchor = document.getElementById(elementName);
          if($anchor) {
            console.log($anchor);
            const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
            window.scroll({
              top: offsetTop - offset,
              behavior: 'smooth'
            })
          }
        } catch (err) {
          console.error("_smoothScroll failed:", err);
        }
      }

    render() {
        return (
            <div id="home" className="header">
                <div className="container">
                    <div className="header-top">
                        <div className="inner-top">
                            {/* <div className="col-md-3 top_right">
                                <ul className="f-icons">
                                    <li><a href="#" className="facebook"> </a></li>
                                    <li><a href="#" className="p"> </a></li>
                                    <li><a href="#" className="twitter"> </a></li>
                                    <li><a href="#" className="isto"> </a></li>
                                </ul>
                            </div> */}
                            <div className="logo">
                                <a href="#"><h1><span>Sino Tennis Club</span></h1></a>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="main-nav">
                            <span className="menu" onClick={this._toggle}></span>
                            <div className="top-menu" id="top-menu">
                                <ul className="cl-effect-16">
                                    <li><a className="scroll" href="#home" data-hover="Home">Home</a></li>
                                    <li><a className="scroll" href="#events" data-hover="Activities & Events">Activities & Events</a></li>
                                    <li><a className="scroll" href="#memberform" data-hover="Memberships">Memberships</a></li>
                                    {/* <li><a className="scroll" href="#video" data-hover="Video">Video</a></li>
                                    <li><a className="scroll" href="#trainer" data-hover="Trainers">Trainers</a></li> */}
                                    <li><a className="scroll" href="#gallery" data-hover="Gallery">Gallery</a></li>
                                    {/* <li><a className="scroll" href="#test" data-hover="Testimonials">Testimonials</a></li>
                                    <li><a className="scroll" href="#partner" data-hover="Partners">Partners</a></li> */}
                                    <li><a className="scroll" href="#sponsors" data-hover="Sponsors and Partners">Sponsors and Partners</a></li>
                                    <li><a className="scroll" href="#contact" data-hover="Contact Us">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}